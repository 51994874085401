<div *ngIf="!useSuggestedSkillsView" class="tag-control">
  <dgx-simple-field-wrapper
    *ngIf="!hideLabel; else tagsSearch"
    class="l_flex-grow"
    [label]="label"
    [isRequired]="required"
    [tipText]="tagToolTip"
    [icon]="tooltipIcon"
    [showFullWidthLabel]="showFullWidthLabel"
    labelFor="tagSearch"
    ><ng-container
      *ngTemplateOutlet="tagsSearch; context: { shouldHideLabel: true }"
    ></ng-container
  ></dgx-simple-field-wrapper>
</div>

<ng-template let-shouldHideLabel="shouldHideLabel" #tagsSearch>
  <dgx-tags-search
    class="guts-m-b-half"
    [placeholderText]="placeholder"
    [hideTopTags]="hideTopTags"
    [hideLabel]="shouldHideLabel || hideLabel"
    [hint]="hint"
    [allowExistingOnly]="allowExistingOnly"
    [showHintAlone]="showHintAlone"
    [topTags]="topTags"
    [tags]="tags"
    [suggestSkills]="suggestSkills"
    [useSuggestedSkillsView]="useSuggestedSkillsView"
    [useNewTagsDesign]="useNewTagsDesign"
    [loadingJobRoleAISkillSuggestions]="loadingJobRoleAISkillSuggestions"
    [useSkillProficiencyLevels]="useSkillProficiencyLevels"
    (addTag)="onAddTag($event)"
    (removeTag)="onRemoveTag($event)"
    (showError)="onShowTagError($event)"
  ></dgx-tags-search>

  <span
    class="tags_search__message tags_search__message--error"
    *ngIf="skillAddError"
    aria-live="polite"
  >
    <df-icon icon="arrow-down-right" size="small" class="arrow_right"></df-icon>
    {{ skillAddError }}
  </span>
</ng-template>

<div *ngIf="useSuggestedSkillsView" class="l_flex l_flex-column">
  <label>{{ label }}</label>
  <span class="l_flex-grow l_flex l_flex-column">
    <ng-container
      *ngTemplateOutlet="tagsSearch; context: { shouldHideLabel: true }"
    ></ng-container>
  </span>
</div>
